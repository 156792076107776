import { template as template_786b3d1d307c42109bce86036c1b806b } from "@ember/template-compiler";
const SidebarSectionMessage = template_786b3d1d307c42109bce86036c1b806b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
