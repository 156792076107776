import { template as template_ebb98d5e51334fe983f3272b7b05bbca } from "@ember/template-compiler";
const WelcomeHeader = template_ebb98d5e51334fe983f3272b7b05bbca(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
