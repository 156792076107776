import { template as template_1e9665df121142cc9f33124ad9e63814 } from "@ember/template-compiler";
const FKText = template_1e9665df121142cc9f33124ad9e63814(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
